import React, { useState, useEffect, useRef } from "react";
import { TileLayer } from 'react-leaflet';
import L from "leaflet";
import FullscreenControl from "react-leaflet-fullscreen";
import 'react-leaflet-fullscreen/dist/styles.css';
import { Paper} from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import SatelliteIcon from '@material-ui/icons/Satellite';
import MapIcon from '@material-ui/icons/Map';

export const MapLayerControl = () => {
    const divRef = useRef(null);
    const [mode, setMode] = useState("sat");

    const tilelayerIgn = 'https://wxs.ign.fr/pratique/geoportail/wmts?'+
    '&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&TILEMATRIXSET=PM'+
    '&LAYER=ORTHOIMAGERY.ORTHOPHOTOS'+
    '&STYLE=normal'+
    '&FORMAT=image/jpeg'+
    '&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}';
    
    
    const tilelayerIgnPlan = 'https://wxs.ign.fr/pratique/geoportail/wmts?'+
    '&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&TILEMATRIXSET=PM'+
    '&LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2'+
    '&STYLE=normal'+
    '&FORMAT=image/png'+
    '&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}';
    
    const tilelayerNames = 'https://wxs.ign.fr/topographie/geoportail/wmts?'+
    '&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&TILEMATRIXSET=PM'+
    '&LAYER=GEOGRAPHICALNAMES.NAMES'+
    '&STYLE=normal'+
    '&FORMAT=image/png'+
    '&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}';
    
    const tilelayerRoad = 'https://wxs.ign.fr/topographie/geoportail/wmts?'+
    '&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&TILEMATRIXSET=PM'+
    '&LAYER=TRANSPORTNETWORKS.ROADS'+
    '&STYLE=normal'+
    '&FORMAT=image/png'+
    '&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}';

    useEffect(() => {
        L.DomEvent.disableClickPropagation(divRef.current);
    });

    return (
        <>
            { (mode==="sat") && <>
                    <TileLayer url={tilelayerIgn} opacity={1}  attribution='<a href="https://www.ign.fr/">IGN.FR</a>'/>
                    <TileLayer url={tilelayerRoad} opacity={0.8} minZoom={13}/>
                    <TileLayer url={tilelayerNames} opacity={1} />
            </> }
            { (mode==="map") && <>
                <TileLayer url={tilelayerIgnPlan} opacity={1} attribution='<a href="https://www.ign.fr/">IGN.FR</a>'/>
            </> }
            <Paper  style={{ height: 62, width: 44, position:"absolute", zIndex:999, top: "12px",right: "12px" }} ref={divRef}><>
                <ButtonGroup  size="small"  color="primary" aria-label="primary button group"orientation="vertical" >
                    <Button disabled={mode==="sat"} onClick={(e) => {e.preventDefault();  e.stopPropagation(); setMode("sat"); }}><SatelliteIcon></SatelliteIcon></Button>
                    <Button disabled={mode==="map"} onClick={(e) => {e.preventDefault();  e.stopPropagation(); setMode("map"); }}><MapIcon></MapIcon></Button>
                </ButtonGroup>
                </>
            </Paper>

            <FullscreenControl position="topleft" />
        </>
    )
}